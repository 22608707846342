'use strict'
const {isNumber} = require('../../../../utils/validationUtils')

/**
 * Calc Z by perspective an scale
 * Normalized to minimum of -999999px
 * https://stackoverflow.com/a/13505718
 * @param {number} perspective
 * @param {number} scale
 */
const getZ = (scale, perspective) => perspective * (scale - 1) / (scale || 0.0001)

const name = 'BaseBgZoom'
const properties = {
    hideOnStart: false,
    getMaxTravel(elementMeasure, viewportHeight) {
        return viewportHeight + elementMeasure.height
    },
    groups: ['animation', 'background'],
    schema: {
        duration: {
            type: 'number',
            min: 0,
            default: 0
        },
        delay: {
            type: 'number',
            min: 0,
            default: 0
        },
        perspectiveParent: {
            type: 'element'
        },
        viewportHeight: {
            type: 'number',
            min: 0
        },
        speedFactor: {
            type: 'number',
            default: 0
        },
        baseScale: {
            type: 'number',
            min: 0,
            default: 1
        },
        in: {
            type: 'object',
            properties: {
                start: {
                    type: 'number',
                    min: 0,
                    max: 1
                },
                end: {
                    type: 'number',
                    min: 0,
                    max: 1
                },
                ease: {
                    type: 'string'
                },
                scale: {
                    type: 'number',
                    min: 0
                }
            }
        },
        out: {
            type: 'object',
            properties: {
                start: {
                    type: 'number',
                    min: 0,
                    max: 1
                },
                end: {
                    type: 'number',
                    min: 0,
                    max: 1
                },
                ease: {
                    type: 'string'
                },
                scale: {
                    type: 'number',
                    min: 0
                }
            }
        }
    }
}

function register({engine, factory}, frame) {
    const perspective = 100

    /**
     * Rotate balata media elements on scroll
     * @param {HTMLElement} elements DOM elements to animate
     * @param {Number} [duration]
     * @param {Number} [delay]
     * @param {Object} [params] Timeline optional parameters (Tween values cannot be changed here, use BaseFade).
     * @returns {TimelineMax}
     */
    function animation(elements, duration, delay, {
        speedFactor = properties.schema.speedFactor.default,
        baseScale = properties.schema.baseScale.default,
        perspectiveParent,
        viewportHeight = frame.offsetHeight || frame.innerHeight || 0,
        in: {
            start: in_start,
            end: in_end,
            ease: in_ease,
            scale: in_scale
        } = {},
        out: {
            start: out_start,
            end: out_end,
            ease: out_ease,
            scale: out_scale
        } = {},
        ...params
    } = {}) {
        if (!perspectiveParent) {
            console.warn(`santa-animations: ${name}: "perspectiveParent: element" is a mandatory parameter for this animation`)
        }

        const sequence = factory.sequence(params)
        const hasInAnimation = isNumber(in_start) && isNumber(in_end)
        const hasOutAnimation = isNumber(out_start) && isNumber(out_end)
        const height = engine.getBoundingRect(perspectiveParent).height

        // set sequence length to full duration and adjust perspective origin to movement
        sequence.add(factory.animate('BasePosition', perspectiveParent, duration, delay, {
            from: {perspective, perspectiveOrigin: `50% ${height / 2 - viewportHeight * speedFactor}px`}, // 50% + parallax.from
            to: {perspective, perspectiveOrigin: `50% ${height / 2 + height * speedFactor}px`}, // 50% + parallax.to
            ease: 'none',
            immediateRender: true
        }), 0)

        // Place in animation on timeline
        if (hasInAnimation) {
            if (in_end < in_start) {
                console.warn(`santa-animations: ${name}: "in" end value ${in_end} must be larger than "in" start value ${in_start}`)
            }
            const _duration = duration * Math.max(in_end - in_start, 0)
            const _delay = delay + duration * in_start
            sequence.add(factory.animate('BasePosition', elements, _duration, _delay, {
                from: {z: getZ(baseScale, perspective)},
                to: {z: getZ(in_scale, perspective)},
                ease: in_ease,
                force3D: true,
                immediateRender: !hasOutAnimation || in_start < out_start
            }), 0)
        }

        // place out animation on timeline
        if (hasOutAnimation) {
            if (out_end < out_start) {
                console.warn(`santa-animations: ${name}: "out" end value ${out_end} must be larger than "out" start value ${out_start}`)
            }
            const _duration = duration * Math.max(out_end - out_start, 0)
            const _delay = delay + duration * out_start
            sequence.add(factory.animate('BasePosition', elements, _duration, _delay, {
                from: {z: getZ(out_scale, perspective)},
                to: {z: getZ(baseScale, perspective)},
                ease: out_ease,
                force3D: true,
                immediateRender: !hasInAnimation || out_start < in_start
            }), 0)
        }

        return sequence.get()
    }

    factory.registerAnimation(name, animation, properties)
}

module.exports = {
    name,
    properties,
    register
}
