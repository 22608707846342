'use strict'
const name = 'BgUnwind'
const properties = {
    hideOnStart: false,
    getMaxTravel(elementMeasure, viewportHeight) {
        return viewportHeight + elementMeasure.height
    },
    groups: ['animation', 'background'],
    schema: {
        duration: {
            type: 'number',
            min: 0,
            default: 0
        },
        delay: {
            type: 'number',
            min: 0,
            default: 0
        },
        clipParent: {
            type: 'element'
        }
    }
}

function register({factory}) {
    /**
     * @param {HTMLElement|HTMLElement[]} elements DOM elements to animate
     * @param {Number} [duration]
     * @param {Number} [delay]
     * @param {Object} [params] Timeline optional parameters.
     * @returns {TimelineMax}
     */
    function animation(elements, duration, delay, {
        clipParent,
        ...params
    } = {}) {
        const sequence = factory.sequence(params)

        sequence.add([
            factory.animate('BaseBgFade', elements, duration, delay, {
                in: {
                    start: 0.15,
                    end: 0.4,
                    opacity: 0,
                    ease: 'none'
                }
            }),
            ...elements.map(element => factory.animate('BaseBgRotate', element.children, duration, delay, {
                in: {
                    start: 0,
                    end: 0.5,
                    rotation: 30,
                    ease: 'none'
                }
            })),
            ...elements.map(element => factory.animate('BaseBgClipPath', element.children, duration, delay, {
                clipParent,
                in: {
                    start: 0,
                    end: 0.5,
                    direction: 'center',
                    minimum: 0,
                    ease: 'none'
                }
            }))
        ])

        return sequence.get()
    }

    factory.registerAnimation(name, animation, properties)
}

module.exports = {
    name,
    properties,
    register
}
